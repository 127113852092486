import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getTranslation } from '../helper/translation';
import Icon from '../modules/icon';
import { SettingsContext } from '../modules/layout';
import Button from './button';
import Input from './input';
import { Helmet } from 'react-helmet';

const chatOptions = {
  entranceId: '04e1a8f8-cf1b-41b2-9dd0-b9fb3915a1d6',
  acdLabel: 'acd2009',
  chatServiceUrl: 'https://api2000.multichannelacd.de',
  configServiceUrl: 'https://data2000.multichannelacd.de',
  staticContentUrl: 'https://static2000.multichannelacd.de',
  branding: {
    chat: true,
    avatars: false,
    name: 'vier'
  }
};

const ContactCenter = () => {
  const [open, setOpen] = useState(false);
  const [chatApi, setChatApi] = useState(null);
  const [chatAvailable, setChatAvailable] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isPageBottom, setIsPageBottom] = useState(false);

  useEffect(() => {
    if (open) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//static2000.multichannelacd.de/chat/v1/product/acdacd2009/js/4com.lsm.starter-api.min.js';
      script.onload = function () {
        if (window?.livesupport?.api) {
          window.livesupport.api.load(chatOptions, (api) => {
            api.acquireChatStatus((chatStatus) =>
              setChatAvailable(chatStatus.status)
            );
            setChatApi(api);
          });
        }
      };

      document.body.appendChild(script);
    }
  }, [open]);

  useEffect(() => {
    const onScroll = () => {
      const isPageBottom = document.body.offsetHeight + window.scrollY + 100 > document.body.scrollHeight;
      setIsPageBottom(isPageBottom)
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClose = () => setToggleModal(false);

  const handleSubmit = () => {
    if (!firstName) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setToggleModal(false);
    chatApi.person.provideFirstName(firstName).provideLastName(lastName);
    chatApi.startChat(
      () => {},
      (e) => console.error(e)
    );
    setFirstName('');
    setLastName('');
  };

  return (
    <SettingsContext.Consumer>
      {(settings) => {
        const { microcopy } = settings;

        return (
          <>
            <Modal
              className="contact-modal"
              show={toggleModal}
              onHide={handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {getTranslation('contactCenter.modal.headline', microcopy)}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>
                    {getTranslation('contactCenter.modal.subtext', microcopy)}
                  </p>
                </div>
                <Input
                  name="firstName"
                  placeholder={getTranslation(
                    'contactCenter.modal.firstName',
                    microcopy
                  )}
                  className={`w-100 py-2 px-1 ${
                    !isValid ? 'border border-danger' : 'mb-3'
                  }`}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                {!isValid && (
                  <span className="mb-2 pb-1 text-danger w-100">
                    {getTranslation(
                      'contactCenter.modal.validationWarning',
                      microcopy
                    )}
                  </span>
                )}

                <Input
                  name="lastName"
                  placeholder={getTranslation(
                    'contactCenter.modal.lastName',
                    microcopy
                  )}
                  className="w-100 mb-3 py-2 px-1"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />

                <Button
                  type="primary"
                  className="d-inline-block text-center"
                  text={getTranslation('contactCenter.modal.sendButton', microcopy)}
                  onClick={handleSubmit}
                />
              </Modal.Body>
            </Modal>
            <div className={`contact-center ${open ? 'open' : ''}`}>
              {open ? (
                <div className={'contact-center-container'}>
                  <div
                    className={
                      'contact-center-header d-flex justify-content-between align-items-center py-3'
                    }
                  >
                    {getTranslation('contactCenter.headline', microcopy)}
                    <Icon
                      className={'contact-center-close'}
                      name={'crossLine'}
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className={'contact-center-content py-3'}>
                    <p>{getTranslation('contactCenter.content', microcopy)}</p>
                    <a
                      href={`tel:${getTranslation(
                        'contactCenter.phoneNumber',
                        microcopy
                      )}`}
                    >
                      <Button
                        className={'contact-center-call-button w-100'}
                        text={
                          <>
                            <Icon name={'phoneLine'} className={'mr-2'} />
                            {getTranslation('contactCenter.call', microcopy)}
                          </>
                        }
                        type={'secondary'}
                      />
                    </a>
                    <a
                      href={`mailto:${getTranslation(
                        'contactCenter.mailAddress',
                        microcopy
                      )}`}
                    >
                      <Button
                        className={'contact-center-mail-button w-100 mt-3'}
                        text={
                          <>
                            <Icon name={'mail'} className={'mr-2'} />
                            {getTranslation('contactCenter.mail', microcopy)}
                          </>
                        }
                        type={'secondary'}
                      />
                    </a>
                    {chatAvailable && (
                      <Button
                        onClick={() => setToggleModal(true)}
                        className={'contact-center-mail-button w-100 mt-3'}
                        text={
                          <>
                            <Icon name={'chat'} className={'mr-2'} />
                            {getTranslation('contactCenter.chat', microcopy)}
                          </>
                        }
                        type={'secondary'}
                      />
                    )}
                    <p className="pt-4">{getTranslation('contactCenter.supportText', microcopy)}</p>
                    <a
                      href={getTranslation('contactCenter.supportLink', microcopy)}
                      target="_blank"
                      rel="noopener nofollow"
                    >
                      <Button
                        className={'contact-center-mail-button w-100'}
                        text={
                          <>
                            <Icon name={'float'} className={'mr-2'} />
                            {getTranslation('contactCenter.supportButton', microcopy)}
                          </>
                        }
                        type={'secondary'}
                      />
                    </a>
                  </div>
                </div>
              ) : (
                !isPageBottom && 
                <p
                  className={'contact-center-label'}
                  onClick={() => setOpen(true)}
                >
                  {getTranslation('contactCenter.title', microcopy)}
                </p>
              )}
            </div>
          </>
        );
      }}
    </SettingsContext.Consumer>
  );
};

export default ContactCenter;
