import React, { useContext, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import SearchIcon from '../../../static/icons/icon_search.svg';
import Button from '../../components/button';
import { getLocalizedLink, getTranslation } from '../../helper/translation';
import Icon from '../icon';
import LanguageSwitch from '../languageSwitch';
import { SettingsContext } from '../layout';
import Link from '../link';
import { NavMenu } from './NavMenu/NavMenu';
import SearchOverlay from '../../modules/search/searchOverlay';

export const DesktopNavigation = () => {
  const { megaMenu, node_locale, microcopy } = useContext(SettingsContext);
  const [ searchVisible, setSearchVisible ] = useState(false)

  const handleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };

  return (
    <>
    <header className={`desktop ${searchVisible ? 'searchVisible' : ''}`}>
      <Container fluid>
        <Navbar expand="md" bg="none">
          <div className="nav-bottom-row">
            <Navbar.Brand href={getLocalizedLink('/', node_locale)}>
              <img
                width="70"
                height="18"
                src="/images/vier_logo.svg"
                alt="Vier logo"
                className="vier-logo"
              />
            </Navbar.Brand>
            <Nav>
              <ul className="navbar-nav">
                {megaMenu?.map(({ name, items, page }) => (
                  <>
                    {name && page?.localizedPage && (
                      <li className="nav-item" key={name}>
                        <Link
                          className="nav-link"
                          to={
                            name === 'home' ? '/' : `/${page?.fullSlug}`
                          }
                        >
                          {name}
                        </Link>
                        <NavMenu items={items} />
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </Nav>
            <div className="nav-search-container">
              <a
                href={`tel:${getTranslation(
                  'contactCenter.phoneNumber',
                  microcopy
                )}`}
              >
                <Button
                  className={'contact-center-call-button'}
                  text={
                    <>
                      <Icon name={'phoneLine'} />
                        <span className={'hideOnNarrow'} >
                          {getTranslation(
                            'contactCenter.call',
                            microcopy
                          )}
                        </span>
                    </>
                  }
                  type={'secondary'}
                />
              </a>
              <a
                href={`mailto:${getTranslation(
                  'contactCenter.mailAddress',
                  microcopy
                )}`}
              >
                <Button
                  className={'contact-center-mail-button'}
                  text={
                    <>
                      <Icon name={'mail'} className={'mr-2'} />
                        <span className={'hideOnNarrow'} >
                          {getTranslation(
                            'contactCenter.mail',
                            microcopy
                          )}
                        </span>
                    </>
                  }
                  type={'secondary'}
                />
              </a>


              <Link
                className={'login-link'}
                to={{
                  url: getLocalizedLink(
                    getTranslation('login.link', microcopy),
                    node_locale
                  )
                }}
              >
                {getTranslation('login.text', microcopy)}
              </Link>

              <LanguageSwitch />
              <Button 
                className={'search-link'} 
                onClick={() => handleSearchVisibility()} 
                text={
                  <SearchIcon />
                }
              />
            </div>
          </div>
        </Navbar>
      </Container>
    </header>
    {searchVisible && (
      <SearchOverlay onClose={() => setSearchVisible(false)} />
    )}
    </>
  );
};
